@tailwind base;
@tailwind components;
@tailwind utilities;

.linear-gradient {
	background: transparent
		linear-gradient(186deg, var(--p-color) 0%, var(--s-color) 100%) 0% 0%
		no-repeat padding-box;
}

* {
	padding: 0px;
	margin: 0px;
	font-family: "Lato";
}

body {
	font-family: "Lato";
	height: 100vh;
	width: 100vw;
}

.bg-image {
	background-image: url(../public/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
	display: none;
}

/* S: df stars here  */

#main {
	margin: 0px;
}

.word-break-all {
	/* word-break: break-all; */

	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

@layer no-tailwindcss {
	/* This layer is disabled */
	@variants {
		.no-tailwindcss {
			all: unset;
		}
	}
}

/* E: df stars here  */
